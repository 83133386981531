import Select2 from '~/scripts/lib/Select2.js'

export function rLog(label, value) {
  if (window.localStorage && window.localStorage.rLog) {
    label = '__' + label + '__';

    if (value) {
      console.log(label, value);
    } else {
      console.log(label);
    }
  }
}

export function browserDetector() {
  let browser;
  let $html = $("html");

  if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
      browser = "ios";
      Modernizr.ios = true;
  } else if (navigator.userAgent.match(/(Android|android)/i)) {
      Modernizr.android = true;
      $html.addClass('android');
      browser = "android";
  } else if ($("body > div#ie").length > 0 || "ActiveXObject" in window) {
      Modernizr.ie = true;
      browser = "ie";
  } else if (jQuery && jQuery.browser && jQuery.browser.chrome) {
      browser = "chrome";
  } else if (jQuery && jQuery.browser && jQuery.browser.mozilla) {
      browser = "firefox";
  } else if (jQuery && jQuery.browser && jQuery.browser.safari) {
      browser = "safari";
  } else if (navigator.userAgent.match(/(Android|android)/i)) {
      Modernizr.android = true;
      $html.addClass('android');
      browser = "android";
  }

  $html.addClass(browser);
};

export function initTooltips() {
  if (!Modernizr.touch && $.fn.tooltip) {
    $("[title]").tooltip({
      title: function() {
       if ($(this).attr('class') === 'select2-selection__rendered'){
        return $(this).text();
       }
      },
      placement: setPlacementForTooltip,
      delay: 500
    });
  }
}

export function setPlacementForTooltip(_, element){
  if ($(element).closest(".trumbowyg-button-pane").length) {
    return 'top';
  } else {
    return 'bottom';
  }
}

export function setupSelectFilters() {
  [
    ".company-roles-select",
    ".team-select",
    ".badge-select"
  ].forEach(selector => {
    new Select2(function () {
      var $select = $(selector)
        .select2(R.utils.companyRoleSelect2TemplateOpts({ theme: 'bootstrap-5' }));
      $select.on("select2:select", selectFilterEvent);
    });
  });

  function selectFilterEvent() {
    var $this = $(this), queryObj, url;
    queryObj = R.utils.queryParams();
    if ($this.val() === "all") {
      delete queryObj[$this.prop('name')];
    } else {
      queryObj[$this.prop('name')] = $this.val();
    }
    var queryParams = $.param(queryObj);
    url = window.location.pathname + "?" + queryParams;
    if (window.location.hash !== "") {
      url += window.location.hash;
    }
    window.location = url;
  }
}

export function queryParams(callback, url) {
  var searchStr = url || window.location.search;
  if (typeof searchStr === "undefined" || searchStr === "" || searchStr.indexOf('?') == -1) {
    return {};
  }

  var obj = paramStringToObject(
    searchStr.slice(searchStr.indexOf('?') + 1),
    callback
  );
  return obj;
}

export function paramStringToObject(paramString, callback) {
  // PETE - 2015-06-10
  // This algorithm is pretty terrible, as I'm starting to hack it up
  // We should probably look to replace this
  var obj = {},
        hashes = decodeURIComponent(paramString).split('&');

  for(var i = 0; i < hashes.length; i++)
  {
      // split on = but not == because of base64
      // TODO: there has to be a better way, but because of the 2nd '='
      // character negation class, the first letter of value will go away
      // with the split, so i bring it back by wrapping it in a group
      // and join it up later
      var paramArray = hashes[i].split(/=([^=])/);

      if(paramArray[1]) { // if there is a value
        paramArray = [paramArray[0], paramArray[1]+paramArray[2]];
      } else {
        paramArray = [paramArray[0].split(/=/)[0], ""];
      }

      if(callback) {
        paramArray = callback(paramArray[0], paramArray[1]);
      }
      obj[paramArray[0]] = paramArray[1];
  }
  return obj;
}

export function renderFeedbackMessageAfterSave(message, $setting, fadeOutTime, textColor){
  var $message = $("<div class='message'>").html(message).addClass("text-"+textColor);
  var $label = $setting.parent().children("label"); // Allow $setting to be a label itself
  var $existingMessage = $label.children('.message');
  if ($existingMessage.length) {
    /*
     * Presumes this .message element(s) was added by this same util method
     * The presence of these existing .message elements are non-problematic in most cases,
     *   but the duplication is visible when performing multiple consecutive updates quickly
     */
    $existingMessage.replaceWith($message);
  } else {
    $label.append($message);
  }
  $message.fadeOut(fadeOutTime);
}

export function copyText(text, button) {
  const $copyButton = $(button);
  const $textToCopy = $(text);
  const that = this;

  $copyButton.on(R.touchEvent, function() {
    navigator.clipboard.writeText($textToCopy.text())
      .then(function() {
        that.writeToast('Copied');
      })
      .catch( function(error) {
        console.error('Failed to copy text: ', error);
      });
  });
}

export function fetchPagePathFromPageID(dataScript) {
  let [controllerBase, action] = dataScript.split('-');
  // Remove cms/ from file path
  controllerBase = controllerBase.replace(/^cms\//, '');

  if (controllerBase === 'articles' && action !== 'index') {
      action = 'show';
  }

  return `../scripts/pages/${controllerBase}/${action}.js`;
}
